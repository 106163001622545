<template>
  <div class="container-page">
    <!-- 系统产品 -->
    <div class="section-page system-product">
        <div class="container">
          <h3 class="h3">{{this.$website.hotelSystemName}}</h3>
          <p class="sub-tit">{{this.$website.hotelSystemSynopsis}}</p>
          <div class="system-product-category">
            <div class="grid animate__animated animate__flipInX" v-for="(item,index) of productCategoryList" :key="index">
              <img :src="item.img">
              <p class="name">{{item.name}}</p>
            </div>
          </div>
        </div>
    </div>

    <div class="section-page system-product" :class="{grey:from!='home'}">
      <div class="container">
        <div class="system-product-list">
          <div class="grid" v-for="(item,index) of productList" :key="index">
            <template v-if="index%2!=0">
              <div class="img animate__animated animate__fadeInLeft animate__delay-1s">
                <img :src="item.img">
              </div>
              <div class="txt animate__animated animate__fadeInRight animate__delay-1s">
                <strong class="txt-tit">{{item.name}}</strong>
                <p class="txt-dec">{{item.info}}</p>
                <a href="/product" title="">了解更多</a>
              </div>
            </template>
            <template v-else>
              <div class="txt animate__animated animate__fadeInLeft animate__delay-1s">
                <strong class="txt-tit">{{item.name}}</strong>
                <p class="txt-dec">{{item.info}}</p>
                <a href="/product" title="">了解更多</a>
              </div>
              <div class="img animate__animated animate__fadeInRight animate__delay-1s">
                <img :src="item.img">
              </div>
            </template>
          </div>
        </div>

        <div class="product-other" v-if="from=='home'">
          <a href="/download" class="more" title="">了解更多 →</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


  // category icon
  import categoryImg01 from '@/assets/category01.png'
  import categoryImg02 from '@/assets/category02.png'
  import categoryImg03 from '@/assets/category03.png'
  import categoryImg04 from '@/assets/category04.png'
  import categoryImg05 from '@/assets/category05.png'
  import categoryImg06 from '@/assets/category06.png'
  import categoryImg07 from '@/assets/category07.png'
  import categoryImg08 from '@/assets/category08.png'
  import categoryImg09 from '@/assets/category09.png'

  // 产品
  import product01 from '@/assets/1.png'
  import product02 from '@/assets/2.png'
  import product03 from '@/assets/3.png'
  import product07 from '@/assets/7.png'
  import product08 from '@/assets/8.png'


export default {
  name: "systemProductComponentIndex",
  props:{
    from:{
      type:String,
      default:'home'
    }
  },
  components: {


  },
  mounted() {
      // 产品显示
      if(this.from=='home'){
        this.productList = this.productList.slice(0,3)
      }
  },
  data() {
    return {
      categoryImg01,categoryImg02,categoryImg03,categoryImg04,categoryImg05,categoryImg06,categoryImg07,categoryImg08,categoryImg09,
      product01,product02,product03,product07,product08,


      // 酒店系统分类产品
      productCategoryList:[
        {name:'酒店管理',img:categoryImg01},
        {name:'餐饮管理',img:categoryImg02},
        {name:'人事管理',img:categoryImg03},
        {name:'财务管理',img:categoryImg04},
        {name:'商城管理',img:categoryImg05},
        {name:'报表分析',img:categoryImg06},
        {name:'仓库管理',img:categoryImg07},
        {name:'合同审批',img:categoryImg08},
        {name:'任务管理',img:categoryImg09},
      ],

      // 产品
      productList:[
        {name:'酒店管理系统',img:product01,
          info:'新一代互联网酒店管理平台，轻松满足各种类型酒店 公寓、客栈、民宿需求。为酒店行业的管理带来了全 面互联网解决方案，为民宿、酒店、公寓等提供房态 订单、财务、客史等管理工具；实现一键式直销、 多渠道分销管理。帮助酒店民宿等进行智慧营销， 提升管理效率。支持所有数据多端同步，手机、 电脑随时随地轻松管理。'},
        {name:'餐饮服务系统',img:product02,
          info:'实现科学的信息化管理，包括前台管理、厨房打印、出品管理 会员管理、系统管理、库存管理、结算管理等。 可实现订餐、点菜、结帐、收银、厨房打印等餐饮专业功能。 同时拥有移动扫码点餐、聚合支付等管理系统,使用简单,管理 便捷,轻松上手用;能为客户提供最便捷的移动解决方案。'},
        {name:'财务管理系统',img:product03,
          info:'以业务事项为基础，实时会计、智能财务、精准税务、 敏捷财资为核心理念，构建财务会计、管理会计、 税务服务、报账服务、财资服务、企业绩效、电子档 案服务、共享服务的全新一代财务体系。打造具备实 时、智能、精细、多维、可视、生态的企业数智化财 务云服务平台，助力企业财务数字化转型。'},
        {name:'商城管理系统',img:product07,
          info:'将“线上商城”与“线下门店”销售运营完美融合，线上线下会员、商品、订单数据互联互通 让零售更高效'},
        {name:'人事管理',img:product08,
          info:'涵盖 员工管理：员工档案、职位、离职、合同管理等。\n' +
            '考勤管理，工作时段设定、出勤管理；请假类别、审批流程管理等。\n' +
            '工资社保，绩效赏罚。人力资源，一目了然'},
      ],


    };
  },
  methods: {


  }
};
</script>

<style lang="scss" scoped>

  /* 一站式管理系统介绍 */
  .system-product{
    .system-product-category{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: -95px;
      .grid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 0px rgba(61, 103, 241, 0.14);
        border-radius: 4px;
        text-align: center;
        margin-left: 95px;
        margin-top: 40px;
        img{
          width: 56px;
          height: 56px;
        }
        .name{
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #3D67F1;
          line-height: 24px;
        }
        &:hover{
          -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
          -webkit-animation-name: bounce;
          animation-name: bounce;
        }
      }
    }
    .system-product-list{
      margin-top: 80px;
      .grid{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        .txt{
          text-align: left;
          width: 30%;
          .txt-tit{
            font-size: 36px;
            font-weight: bold;
            color: #252440;
          }
          .txt-dec{
            margin-top: 10px;
            font-size: 18px;
            font-weight: 400;
            color: #868693;
            line-height: 30px;
          }
          a{
            text-align: center;
            display: inline-block;
            margin-top: 20px;
            width: 125px;
            height: 46px;
            line-height: 46px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px 0px rgba(159, 162, 179, 0.49);
            border-radius: 6px;
            font-size: 16px;
            font-weight: 500;
            color: #3D67F1;
            text-decoration: none;
          }
        }
        .img{
          height: 520px;
        }
      }
    }
    .product-other{
      margin-top: 30px;
      a{
        display: inline-block;
        text-decoration: none;
        width: 132px;
        height: 56px;
        line-height: 56px;
        background: #3D67F1;
        border-radius: 6px;
        font-size: 16px;
        color: #FFF;
        transition: all 1s ease;
        &:hover{
          width: 160px;
          transition: width .3s ease
        }
      }
    }
    &.grey{
      background: #F8FAFD;
    }



  }

  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .system-product {
      .system-product-category {
        width: 100%;
        margin-left: 0;
        .grid{
          margin-left: 20px;
          margin-top: 10px;
        }
      }
      .system-product-list{
        margin-top: 40px;
        .grid{
          margin-top: 20px;
          .txt{
            width: 48%;
          }
          .img{
            width: 50%;
            height: 100%;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .system-product {
      .system-product-category {
        width: 100%;
        margin-left: 0;
        .grid{
          margin-left: 10px;
          margin-top: 10px;
          width: 100px;
          height: 100px;
        }
      }
      .system-product-list{
        margin-top: 20px;
        .grid{
          display: block;
          margin-top: 20px;
          .txt{
            width: 100%;
            .txt-tit{
              font-size: 16px;
            }
            .txt-dec{
              font-size: 14px;
            }
          }
          .img{
            width: 100%;
            height: 100%;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }


  }
</style>
