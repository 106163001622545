<template>
  <div class="container-page">

    <!-- 合作伙伴 -->
    <div class="section-page partner" :style="{backgroundImage:'url('+partnerBgImg+')'}">
      <div class="container">
        <h3 class="h3">成功案列</h3>
        <p class="sub-tit">加入我们-创造未来</p>

        <div class="partner-list">
          <div class="grid" :class="{odd:index%2!=0}" v-for="(item,index) of partnerList" :key="index">
            <img :src="item.img">
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>


  // 合作伙伴
  import partnerBgImg from '@/assets/partner-bg.png'
  import partner01Img from '@/assets/partner01.png'
  import partner02Img from '@/assets/partner02.png'
  import partner03Img from '@/assets/partner03.png'
  import partner04Img from '@/assets/partner04.png'
  import partner05Img from '@/assets/partner05.png'
  import partner06Img from '@/assets/partner06.png'
  import partner07Img from '@/assets/partner07.png'
  import partner08Img from '@/assets/partner08.png'

export default {
  name: "partnerComponentIndex",
  components: {

  },
  data() {
    return {

      partnerBgImg,partner01Img,partner02Img,partner03Img,partner04Img,partner05Img,partner06Img,partner07Img,partner08Img,

      partnerList:[
        {img:partner01Img,name:'3000旅居'},
        {img:partner02Img,name:'中国航天'},
        {img:partner03Img,name:'泊美酒店'},
        {img:partner04Img,name:'好美特酒店'},
        {img:partner05Img,name:'好美特酒店'},
        {img:partner06Img,name:'好美特酒店'},
        {img:partner07Img,name:'好美特酒店'},
        {img:partner08Img,name:'好美特酒店'},
      ],

    };
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

  /* 合作伙伴 */
  .partner{
    height: 535px;
    padding-top: 65px;
    background: rgba(61, 103, 241, 1);
    .h3{
      color: #fff;
    }
    .sub-tit{
      color: #fff;
    }

    .partner-list{
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .grid{
        display: flex;
        width: 25%;
        height: 140px;
        justify-content: center;
        align-items: center;
        background: #fff;
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(7){
          background: #F5F6F9;
        }
        &:nth-child(1){
          border-radius: 6px 0 0 0;
        }
        &:nth-child(4){
          border-radius:  0 6px 0 0;
        }
        &:nth-child(5){
          border-radius: 0 0 0 6px;
        }
        &:nth-child(8){
          border-radius: 0 0  6px 0;
        }
      }
    }
  }


  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .partner {
      height: 480px;
      padding-top: 40px;
      background-size: contain;
      background-repeat: no-repeat;
      .partner-list {

      }
    }

  }
  @media screen and (max-width: 767px) {
    .partner {
      height: 350px;
      padding-top: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      .partner-list {
        .grid{
          height:70px;
          img{
            width: 90%;
            height: 90%;
          }
        }
      }
    }
  }

</style>
