<template>
  <div class="container-page">

    <!-- 智能产品 -->
    <div class="section-page intellect-product">
        <div class="container">
          <h3 class="h3">智能产品</h3>
          <p class="sub-tit">Intelligent products</p>

          <div class="intellect-product-list">
              <div class="grid animate__animated animate__fadeInLeft animate__delay-1s">
                <strong class="tit">{{intellectProductList[0].name}}</strong>
                <p class="dec">{{intellectProductList[0].info}}</p>
                <div class="more">
                  <a href="/product"  title="">了解更多 →</a>
                </div>
                <div class="img img01 animate__animated animate__fadeInUp animate__delay-1s">
                  <img class="" :src="intellectProductList[0].img">
                </div>
              </div>
              <div class="grid">
                <div class="sub-grid animate__animated animate__fadeInRight animate__delay-1s">
                  <div class="info">
                    <strong class="tit">{{intellectProductList[1].name}}</strong>
                    <p class="dec">{{intellectProductList[1].info}}</p>
                    <div class="more">
                      <a href="/product"  title="">了解更多 →</a>
                    </div>
                  </div>
                  <div class="img img02">
                    <img class="" :src="intellectProductList[1].img">
                  </div>
                </div>
                <div class="sub-grid animate__animated animate__fadeInRight animate__delay-1s">
                  <div class="info">
                    <strong class="tit">{{intellectProductList[2].name}}</strong>
                    <p class="dec">{{intellectProductList[2].info}}</p>
                    <div class="more">
                      <a href="/product"  title="">了解更多 →</a>
                    </div>
                  </div>
                  <div class="img img03">
                    <img class="" :src="intellectProductList[2].img">
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>

  import product04 from '@/assets/4.png'
  import product05 from '@/assets/5.png'
  import product06 from '@/assets/6.png'

export default {
  name: "intellectProductComponentIndex",
  components: {


  },
  data() {
    return {
      product04,product05,product06,

      intellectProductList:[
        {name:'酒店物联网全景化系统',img:product04,info:'32位ARM高效处理器 集保护、测量、信号、采集、控制、报警等功能于一体。'},
        {name:'智能设备 控制面板',img:product05,info:'精简设计、傻瓜操作 互联智能设备'},
        {name:'智能门锁',img:product06,info:'高速32位ARM工业 控制用芯片'},
      ],


    };
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

  /* 智能产品 */
  .intellect-product{
    background: linear-gradient(180deg, #FCFDFF 0%, #F8FAFD 100%);
    .intellect-product-list{
      margin-top: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .grid{
        height: 680px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 3px rgba(227, 235, 248, 0.89);
        border-radius: 24px;
        padding: 40px 30px;
        width: 50%;
        &:last-child{
          display: flex;
          flex-direction: column;
          justify-content: inherit;
          background: transparent;
          box-shadow: none;
          border-radius:0;
          padding: 0;
          width: 45%;
        }
        .sub-grid{
          display: flex;
          align-items: center;
          height: 320px;
          padding: 40px 30px;
          background: #FFFFFF;
          box-shadow: 0px 2px 10px 3px rgba(227, 235, 248, 0.89);
          border-radius: 24px;
          .info{
            width: 140px;
            padding-right: 30px;
          }

        }
        .tit{
          display: block;
          font-size: 24px;
          text-align: left;
        }
        .dec{
          margin-top: 20px;
          font-size: 18px;
          color: #868693;
          text-align: left;
        }
        .more{
          margin-top: 20px;
          text-align: left;
          a{
            display: inline-block;
            text-align: center;
            text-decoration: none;
            width: 132px;
            height: 56px;
            line-height: 56px;
            font-size: 16px;
            color: #3D67F1;
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid #3D67F1;
            transition: all 1s ease;
            &:hover{
              background-color: #3D67F1;
              color: #fff;
              transition: background-color .3s ease
            }
          }
        }
        .img{
          transition:all 1s ease-out;
          &:hover{
            transform:scale(1.1);
          }
        }
        .img01{
          margin-top: 50px;
          img{
            transition:all 1s ease-out;
            &:hover{
              transform:scale(1.1);
            }
          }
        }
        .img02{
          flex: 1;
        }
        .img03{
          flex: 1;
        }
      }
    }
  }


  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .intellect-product{
      .intellect-product-list{
        .grid{
          height: 566px;
          .img{
            margin-top: 20px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .sub-grid{
            height: 273px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .intellect-product{
      .intellect-product-list{
        flex-direction: column;
        .grid{
          width: 100% !important;
          height: 100%;
          .img{
            margin-top: 30px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .sub-grid{
            flex: 1;
            height: auto;
            margin-top: 10px;
          }
        }
      }
    }
  }

</style>
