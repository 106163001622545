<template>
  <div class="container-page">

    <!-- banner -->
    <header-component-index  :bannerImg="homeBannerImg">
      <div class="banner-slogan">
        <p class="txt animate__animated animate__bounceInDown">贵州石头科技坚持围绕客户需求持续创新，加大基础研究投入，厚积薄发，推动世界进步</p>
        <h2 class="h2 animate__animated animate__lightSpeedInLeft">贵州石头科技&nbsp;&nbsp;智能酒店生活</h2>
        <h2 class="h3 animate__animated animate__fadeInDown" >Interconnection of all things</h2>
        <h4 class="h4 animate__animated animate__fadeInDown">STONE</h4>

        <a href="/about" class="animate__animated animate__fadeInUp" title="">联系我们</a>
      </div>
    </header-component-index>

    <!-- 关于我们 -->
    <about-component-index></about-component-index>

    <!-- 我们能做什么 -->
    <we-do-component-index></we-do-component-index>

    <!-- 系统产品 -->
    <system-product-component-index :from="'home'"></system-product-component-index>

    <!-- 智能产品 -->
    <intellect-product-component-index></intellect-product-component-index>

    <!-- 公司地址 -->
    <address-component-page-index></address-component-page-index>

    <!-- 合作与了解 -->
    <contact-component-index></contact-component-index>

    <!-- 合作伙伴 -->
    <partner-component-index></partner-component-index>

    <!-- 底部 -->
    <footer-component-index></footer-component-index>

  </div>
</template>

<script>
  // assets
  import homeBannerImg from '@/assets/banner-home-bg.png'

  // component
  import FooterComponentIndex from "../components/footer";
  import AboutComponentIndex from "../components/about";
  import WeDoComponentIndex from "../components/weDo";
  import ContactComponentIndex from "../components/contact";
  import PartnerComponentIndex from "../components/partner";
  import SystemProductComponentIndex from "../components/systemProduct";
  import IntellectProductComponentIndex from "../components/intellectProduct";
  import AddressComponentPageIndex from "../components/address";
  import HeaderComponentIndex from "../components/header";



export default {
  name: "HomePageIndex",
  components: {
    HeaderComponentIndex,
    AddressComponentPageIndex,
    IntellectProductComponentIndex,
    SystemProductComponentIndex,
    PartnerComponentIndex,
    ContactComponentIndex,
    WeDoComponentIndex,
    AboutComponentIndex,
    FooterComponentIndex

  },
  data() {
    return {
      homeBannerImg,

    };
  },
  methods: {


  }
};
</script>

<style lang="scss" scoped>
  .banner-slogan{
    padding-top: 220px;
    position: relative;
    .txt{
      height: 25px;
      font-size: 21px;
      font-weight: bold;
      color: #fff;
      line-height: 32px;
      letter-spacing: 1px;
    }
    .h2{
      position: relative;
      margin-top: 55px;
      font-size: 70px;
      color: #fff;
      line-height: 126px;
      letter-spacing: 12px;
      z-index: 10;
    }
    .h3{
      width: 100%;
      position: absolute;
      top:320px;
      font-size: 60px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 900;
      color: rgba(255, 255, 255, .11);
      line-height: 71px;
      letter-spacing: 7px;

    }
    .h4{
      position: absolute;
      width: 100%;
      top:350px;
      font-size: 21px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: rgba(255, 255, 255, .6);
      line-height: 32px;
      letter-spacing: 1px;
    }

    a{
      margin-top: 110px;
      display: inline-block;
      text-decoration: none;
      width: 160px;
      height: 58px;
      line-height: 58px;
      background: #fff;
      font-weight: 500;
      color: #3D67F1;
      border-radius: 29px;
      border: 1px solid #FFFFFF;
      transition: all 1s ease;
      &:hover{
        width: 180px;
        transition: width .3s ease
      }
    }
  }


  @media screen and (min-width: 767px) and (max-width: 1080px) {
    .banner-slogan{
      padding-top: 100px;
      .txt{
        font-size: 16px;
      }
      .h2{
        font-size: 36px;
        line-height: 105px;
      }
      .h3{
        top: 205px;
        font-size: 32px;
      }
      .h4{
        top: 226px;
        font-size: 15px;
      }
      a{
        margin-top: 90px;
      }

    }
  }
  @media screen and (max-width: 767px){
    .banner-slogan{
      padding-top: 50px;
      .txt{
        font-size: 12px;
      }
      .h2{
        line-height: normal;
        font-size: 24px;
        margin-top: 75px;
      }
      .h3{
        line-height: normal;
        top: 205px;
        font-size: 16px;
      }
      .h4{
        display: none;
        top: 226px;
        font-size: 12px;
      }
      a{
        margin-top: 30px;
        height: 48px;
        line-height: 48px;
      }

    }
  }
</style>
