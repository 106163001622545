<template>
  <div class="container-page">

    <!-- 公司地址 -->
    <div class="section-page address">
      <div class="container">
        <h3 class="h3">公司地址</h3>
        <p class="sub-tit">加入我们-创造未来</p>
        <div class="address-map">
          <baidu-map class="map" :zoom="17" :center="markerPoint">
            <bm-info-window :position="markerPoint" :title="infoWindow.title" :show="infoWindow.show" @close="infoWindowClose" @open="infoWindowOpen">
              <p>{{infoWindow.name}}</p>
              <p>{{infoWindow.address}}</p>
            </bm-info-window>
          </baidu-map>
        </div>
        <div class="address-info">
          <div class="grid" v-for="(item,index) of this.$website.addressService" :key="index">
            <div class="tit">{{item.title}}</div>
            <div class="cont">{{item.info}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: "addressComponentPageIndex",
  components: {

  },
  data() {
    return {
      markerPoint:{lng: this.$website.centerLng, lat: this.$website.centerLat},
      infoWindow:{
        show: true,
        title: this.$website.hotelName,
        name: this.$website.enterpriseName,
        address: this.$website.enterpriseAddress,
      },

    };
  },
  methods: {

    // 地图相关
    infoWindowClose (e) {
      this.infoWindow.show = false
    },
    infoWindowOpen (e) {
      this.infoWindow.show = true
    },

  }
};
</script>

<style lang="scss" scoped>

  /* 地址相关 */
  .address{
    .address-map{
      margin-top: 50px;
      width: 100%;
      height: 358px;
      .map{
        width: 100%;
        height: 100%;
      }
    }
    .address-info{
      margin-top: 50px;
      background: #3D67F1;
      border-radius: 6px;
      padding: 30px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .grid{
        text-align: left;
        width: 25%;
        color: #fff;
        .tit{
          font-size: 21px;
        }
        .cont{
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }


  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .address{
      .address-map{
        margin-top: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .address{
      .address-map{
        margin-top: 10px;
      }
      .address-info{
        margin-top: 20px;
        padding: 20px;
        display: block;
        .grid{
            width: 100%;
          margin-bottom: 20px;
          .tit{
            font-size:16px;
          }
          .cont{
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
