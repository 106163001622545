<template>
  <div class="container-page">

    <!-- 关于我们 -->
    <div class="section-page about">
        <div class="container">
          <h3 class="h3">关于我们</h3>
          <p class="sub-tit">加入我们-创造未来</p>

          <div class="container-html" v-html="aboutHtml">

          </div>
        </div>
      </div>

  </div>
</template>

<script>
  import aboutBgImg from '@/assets/about-bg.png'

export default {
  name: "aboutComponentIndex",
  components: {

  },
  data() {
    return {
      aboutBgImg,
      aboutHtml:`
          <p class="txt-description animate__animated animate__slideInLeft">贵州石头科技是全球领先的智能提供商，我们致力于把智能生活带入每个人、每个家庭、每个组织，构建万物互联的智能生活。 目前贵州石头科技在多个领域提供最终解决方案，主要服务于酒店、餐饮、旅游、装饰等行业。</p>
          <p class="txt-description animate__animated animate__slideInRight">我们在智能和云服务等领域为客户提供有竞争力、安全可信赖的产品、解决方案与服务，与生态伙伴开放合作，持续为客 户创造价值，释放个人潜能，丰富家庭生活，激发组织创新。 贵州石头科技坚持围绕客户需求持续创新，加大基础研究投入，厚积薄发，推动世界进步。</p>
          <img class="img" style="height: 485px;
      margin-top: 35px;" src="${aboutBgImg}">`
    };
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 767px) and (max-width: 1080px) {


  }
  @media screen and (max-width: 767px){


  }

</style>
