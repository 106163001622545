<template>
  <div class="container-page">

    <!-- 我们能做什么 -->
    <div class="section-page we-do">
        <div class="container">
          <h3 class="h3">我们能做什么</h3>
          <p class="sub-tit">支持多样化智能业务和服务定制</p>
          <div class="we-do-item">
            <div class="grid" v-for="(item,index) of weDoList" :key="index">
              <h4 class="h4" :style="{backgroundImage:'url('+item.img+')'}">{{item.name}}</h4>
              <dl class="sub-grid animate__animated " :class="{'animate__slideInLeft':item.type==1,'animate__slideInRight':item.type==2}" v-for="(subItem,subIndex) of item.list" :key="subIndex">
                <dt class="tit">{{subItem.title}}</dt>
                <dd class="cont">{{subItem.info}}</dd>
              </dl>
              <a href="/product" title="">了解更多</a>
            </div>

          </div>
        </div>
    </div>

  </div>
</template>

<script>

  import titleBlueBgImg from '@/assets/title-blue-bg.png'
  import titleGreyBgImg from '@/assets/title-grey-bg.png'

export default {
  name: "weDoComponentIndex",
  components: {

  },
  data() {
    return {
      titleBlueBgImg,titleGreyBgImg,

      // 我们能做什么
      weDoList:[
        {name:'针对企业用户',type:1,img:titleBlueBgImg,list:[
            {title:'办公应用',info:'仓库管理、人事管理、项目管理、工作申请、财务管理、云合同'},
            {title:'设备管理',info:'强电管理、上下水系统、空调系统、 闸机系统、消费预警系统'},
            {title:'ERP',info:'酒店系统、餐饮系统、 商品销售系统'},
          ]},
          {name:'针对个人用户',type:2,img:titleGreyBgImg,list:[
              {title:'智能家居',info:'智能门锁、智能灯光、空气检测、空调控制、窗帘控制'},
              {title:'生活',info:'智能出行、酒店预订、餐饮服务、在线购物、景区服务'},
              {title:'工作/项目',info:'个人工作室、多人项目协作'},
            ]},
      ],

    };
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

  /* 我们能做什么 */
  .we-do{
    background: #F8FAFD;
    .we-do-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 45px;
      .grid{
        width: 520px;
        box-shadow: 0px 2px 12px 3px rgba(227, 235, 248, 0.89);
        border-radius: 6px;
        &:first-child{
          margin-left: 0;
          .h4{
            color: #fff;
          }
        }
        .h4{
          padding: 0 25px;
          height: 83px;
          line-height: 83px;
          font-size: 24px;
          font-weight: bold;
          color: #454469;
          background-repeat: no-repeat;
          background-position: center;
          text-align: left;
        }
        .sub-grid{
          padding: 25px;
          text-align: left;
          .tit{
            position: relative;
            padding-left: 15px;
            font-size: 18px;
            font-weight: 500;
            color: #252440;
            line-height: 27px;
            &:after{
              content: "";
              position: absolute;
              left: 0;
              top: 10px;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background: #3D67F1;
            }
          }
          .cont{
            margin-top: 5px;
            font-size: 16px;
            font-weight: 400;
            color: #868693;
            line-height: 28px;
          }
        }
        a{
          display: inline-block;
          text-decoration: none;
          margin: 25px auto;
          width: 120px;
          height: 48px;
          line-height: 48px;
          background: #FFFFFF;
          border-radius: 6px;
          border: 2px solid #3D67F1;
          font-size: 16px;
          font-weight: 500;
          color: #3D67F1;
          text-align: center;
          transition: all 1s ease;
          &:hover{
            width: 160px;
            transition: width .3s ease
          }
        }
      }
    }
  }


  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .we-do{
      .we-do-item{
        .grid{
          width: 49%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .we-do{
      .we-do-item{
        display: block;
        .grid{
          margin-bottom: 10px;
          width: 100%;
        }
      }
    }
  }

</style>
